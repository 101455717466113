<template>
    <li>
        <span class="top-header">{{ answer.participantName }}</span>
        <div class="row answer-container">
            <div class="col-2 score-controls">
                <button v-on:click="decrement">➖</button>
                <span class="score">{{ score }}</span>
                <button v-on:click="increment">➕</button>
            </div>
            <pre class="col-10 answer">{{ answer.text }}</pre>
        </div>
    </li>
</template>

<style scoped>
.score {
    font-size: x-large;
}
</style>

<script>
export default {
    props: {
        answer: Object
    },
    data(){
        return {
            score: 0
        }
    },
    methods: {
        increment(){
            this.score += 1;
            this.incrementScore(1);
        },
        decrement(){
            this.score -= 1;
            this.incrementScore(-1);
        },
        incrementScore(number){
            var formData = new FormData();
            formData.append("participantId", this.answer.participantId);
            formData.append("score", number);
            this.$http.post(`/api/quiz/${this.answer.quizId}/score`, formData);
        }
    }
}
</script>

<style scoped>
    .answer-container {
        margin-top: -15px;
        padding-top: 20px;
        padding-bottom: 10px;
        border: 2px solid lightgrey;
        border-radius: 00.5em;
    }

    .score-controls {
        display: flex;
        justify-content: space-between;
        align-self: center;
    }

    .top-header {
        display: inline-block;
        background-color: white;
        font-style: italic;
        left: 0.5em;
        padding-left: 10px;
        padding-right: 10px;
    }

    .answer {
        word-wrap: break-word;
    }
</style>