<template>
  <div class="container">
    <div class="row">
      <div class="mainbar">
        <h1>Deltakerlink:</h1>
        <h2>{{ clientUrl }}</h2>
        <br />
        <br />

        <div>
          <h2>Neste spørsmål</h2>
          <textarea
            v-model="next.title"
            placeholder="Title of next question"
          /><br />
          <button v-on:click="sendNextQuestion">SEND!</button>
        </div>

        <br />
        <br />
        <br />

        <div v-if="currentQuestionNumber != 0">
          <h2>
            #{{ currentQuestionNumber }}: {{ current.title }} ({{
              answers.length
            }}
            svar)
          </h2>
          <button v-if="isOpen" v-on:click="closeQuestion">
            Steng (ingen flere svar kan sendes)
          </button>
          <div class="closed" v-if="!isOpen">
            Stengt, ingen flere svar kan sendes!
          </div>
          <button v-if="!isOpen" v-on:click="reopenQuestion">
            Whoops, gjenåpne!
          </button>
          <ul>
            <Answer
              v-for="answer in answers"
              :key="answer.participantId"
              :answer="answer"
            ></Answer>
          </ul>
        </div>
      </div>
      <div class="sidebar">
        <ParticipantList :quizId="quizId"></ParticipantList>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mainbar {
  flex-grow: 3;
}

textarea {
  width: 100%;
  height: 5em;
}
</style>

<script>
import ParticipantList from '@/components/ParticipantList.vue';
import Answer from '@/components/Answer.vue';

export default {
  components: {
    ParticipantList,
    Answer,
  },
  props: {
    quizId: String,
  },
  data() {
    return {
      next: { title: '' },
      current: { title: '' },
      currentQuestionNumber: 0,
      isOpen: true,
      answers: [],
      clientUrl: `${window.location.origin}/${this.quizId}`,
    };
  },
  methods: {
    sendNextQuestion() {
      var formData = new FormData();
      formData.append('title', this.next.title);
      this.$http.post(`/api/quiz/${this.quizId}/next`, formData).then((res) => {
        this.currentQuestionNumber = res.data;
        this.current = this.next;
        this.next = { title: '' };
        this.answers = [];
        this.isOpen = true;
      });
    },
    onNewAnswer(data) {
      if (
        data.quizId == this.quizId &&
        data.questionNumber == this.currentQuestionNumber
      ) {
        this.answers.push(data.answer);
      }
    },
    closeQuestion() {
      this.$http
        .post(`/api/quiz/${this.quizId}/${this.currentQuestionNumber}/close`)
        .then(() => {
          this.isOpen = false;
        });
    },
    reopenQuestion() {
      this.$http
        .post(`/api/quiz/${this.quizId}/${this.currentQuestionNumber}/reopen`)
        .then(() => {
          this.isOpen = true;
        });
    },
  },
  created() {
    this.$questionHub.$on('new-answer', this.onNewAnswer);
  },
};
</script>

<style scoped>
li {
  list-style: none;
}

ul {
  margin-top: 20px;
}

.closed {
  color: maroon;
}
</style>
