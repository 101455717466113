<template>
    <li>
        <div class="row">
            <div class="col-1 no-padding" :class="rankClass">#{{ rank }}</div>
            <div class="col-6">{{ name }}</div>
            <div class="col-2 no-padding">
                {{ score }}
            </div>
            <div class="col-2 yay no-padding" v-if="hasPositiveScoreDelta">
                +{{ scoreDelta }}
            </div>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        name: String,
        rank: Number,
        score: Number,
        previousRank: Number,
        previousScore: Number
    },
    computed:{
        scoreDelta() { 
            return this.score - this.previousScore;
        }, 
        hasPositiveScoreDelta() {
            return this.scoreDelta > 0;
        },
        rankClass() {
            if (this.previousRank == 0) {
                return "";
            }            
            else if(this.rank < this.previousRank) {
                return "rankUp";
            } else if(this.rank > this.previousRank) {
                return "rankDown";
            } else {
                return "";
            }
        }
    },
    methods: { 
    }
}
</script>

<style scoped>
    .yay {
        color: green;
    }

    .no-padding {
        padding: 0;
        text-align: right;
    }

    .rankUp {
        background: url('~@/assets/Green-Up-Arrow.png') no-repeat top center;
        background-size: contain;
    }

    .rankDown {
        background: url('~@/assets/Red-Down-Arrow.png') no-repeat top center;
        background-size: contain;
    }

    .no-padding {
        padding-left: 0;
    }
</style>