<template>
    <div>
        <h3>Participants ({{ numberOfParticipants }})</h3>
        <ul>
            <Participant v-for="participant in orderedParticipants" 
                :key="participant.id" 
                :name="participant.name" 
                :rank="participant.rank" 
                :score="participant.score"
                :previousRank="participant.previousRank"
                :previousScore="participant.previousScore"></Participant>
        </ul>
    </div>
</template>

<script>
import Participant from './Participant.vue'

const ParticipantModel = function(participant) {
    participant.previousRank = 0;
    participant.previousScore = 0;
    return participant;
};

export default {
    components: {
        Participant
    },
    props: {
        quizId: String,
        editable: Boolean
    },
    computed: {
        orderedParticipants() {
            var list = this.participants;
            list = this.calculateRanks(list);
            return list;
        },
        numberOfParticipants() {
            return this.participants.length;
        }
    },
    data() {
        return {
            participants: []
        };
    },
    created() {
        this.$http.get(`/api/quiz/${this.quizId}/participants`).then(res => {
            var list = res.data.map(p => new ParticipantModel(p));
            list = this.calculateRanks(list);
            this.participants = list;
            this.resetCalcs();
        });
        this.$questionHub.$on("new-participant", this.onNewParticipant);
        this.$questionHub.$on("score-changed", this.onScoreChanged);
        this.$questionHub.$on("new-question", this.resetCalcs);
    },
    methods: {
        onNewParticipant(data) {
            if (data.quizId == this.quizId) {
                var list = this.participants;
            
                list.push(new ParticipantModel(data.participant));
                list = this.calculateRanks(list);
                this.participants = list;
            }            
        },
        onScoreChanged(data){
            if (data.quizId == this.quizId){
                var list = this.participants;
                var participant = list.find(p => p.id === data.participant.id);
                participant.score = data.participant.score;
                list = this.calculateRanks(list);
                
                this.participants = list;
            }
        },
        calculateRanks(list){
            list.sort((a,b) => b.score - a.score);
            var rank = 0;
            var previousScore = undefined;
            list.forEach(participant => {
                if (participant.score != previousScore) {
                    rank = rank + 1;
                }
                participant.rank = rank;
                previousScore = participant.score;
            });
            return list;
        },
        resetCalcs(){
            var list = this.participants;
            list = this.calculateRanks(list);
            list.forEach(p => {
                p.previousRank = p.rank;
                p.previousScore = p.score;
            });
            this.participants = list;
        }
    }
}
</script>

<style scoped>
    ul{
        list-style: none;
        padding: 0.5em;
    }
    li {
        border-bottom: 1px dashed #ddd;
    }

    li:last-child {
        border-bottom: none;
    }

    .bigbutton {
        height: 30px;
        width: 30px;
        text-align: center;
        vertical-align: middle;
    }
</style>