import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import QuestionHub from './question-hub'

Vue.config.productionTip = false

// Setup axios as the Vue default $http library
axios.defaults.baseURL = 'https://novanet-quizmaster.azurewebsites.net' // same as the Url the server listens to
Vue.prototype.$http = axios

Vue.use(BootstrapVue);
Vue.use(QuestionHub);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
