import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Quiz from '@/components/Quiz.vue'
import QuizHost from '@/components/QuizHost.vue'
import CreateQuiz from '@/components/CreateQuiz.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/host',
    name: "create",
    component: CreateQuiz
  },
  {
    path: '/:quizId',
    component: Quiz,
    props: true
  },
  {
    path: '/host/:quizId',
    name: "host",
    component: QuizHost,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
