import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
export default {
    install (Vue) {
        const connection = new HubConnectionBuilder()
        .withUrl(`${Vue.prototype.$http.defaults.baseURL}/quiz-hub`)
        .configureLogging(LogLevel.Information)
        .build();

        // use the new Vue instance as an event bus
        const questionHub = new Vue();
        // every component will use this.$questionHub to access the event bus
        Vue.prototype.$questionHub = questionHub;
        // Forward server side SignalR events through $questionHub, where components will listen to them
        connection.on("ScoreChanged", (quizId, participant, score) => {
            questionHub.$emit("score-changed", {quizId, participant, score});
        });
        connection.on("NewParticipant", (quizId, participant) => {
            questionHub.$emit("new-participant", {quizId, participant});
        });
        connection.on("NewQuestion", (quizId, question) => {
            questionHub.$emit("new-question", {quizId, question});
        });
        connection.on("NewAnswer", (quizId, questionNumber, answer) => {
            questionHub.$emit("new-answer", {quizId, questionNumber, answer});
        });
        connection.on("QuestionClosed", (quizId, questionNumber) => {
            questionHub.$emit("question-closed", {quizId, questionNumber});
        });
        connection.on("QuestionReopened", (quizId, questionNumber) => {
            questionHub.$emit("question-reopened", {quizId, questionNumber});
        });

        let startedPromise = null
        function start(){
            startedPromise = connection.start().catch(err => {
                console.error("Failed to connect with hub", err);
                return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
            });
            return startedPromise;
        }
        connection.onclose(() => start());

        start();
    }    
}
