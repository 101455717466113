<template>
  <div>
    <div v-if="!hasQuestion">
      <h2>Waiting for question...</h2>
    </div>
    <div v-else>
      <h3>Question #{{ question.number }}</h3>
      <h3>
        <pre>{{ question.title }}</pre>
      </h3>
      <div v-if="!hasAnswered">
        <textarea v-bind:disabled="!isOpen" v-model="answer"></textarea>
        <br />
        <button v-bind:disabled="!canAnswer" v-on:click="sendAnswer">
          Send answer
        </button>
        <span v-if="!isOpen" style="color: red"
          >Question is closed, no more answers accepted</span
        >
      </div>
      <div v-else>
        <span
          >Your answer: <br />
          {{ answer }}</span
        >
      </div>
      <div v-if="hasPoints">
        <h3>You got {{ points }} points for your answer!</h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>

<script>
export default {
  components: {},
  props: {
    quizId: String,
    participantId: String,
  },
  data() {
    return {
      question: { number: 0, title: '' },
      answer: '',
      hasAnswered: false,
      points: 0,
      isOpen: true,
    };
  },
  created() {
    this.$questionHub.$on('new-question', this.onNewQuestion);
    this.$questionHub.$on('score-changed', this.onScoreChanged);
    this.$questionHub.$on('question-closed', this.onQuestionClosed);
    this.$questionHub.$on('question-reopened', this.onQuestionReopened);
  },
  computed: {
    hasQuestion() {
      return this.question.number > 0;
    },
    hasTypedSomething() {
      return this.answer !== '';
    },
    hasPoints() {
      return this.points !== 0;
    },
    canAnswer() {
      return this.hasTypedSomething && this.isOpen;
    },
  },
  methods: {
    onNewQuestion(data) {
      if (this.quizId == data.quizId) {
        this.question = data.question;
        this.answer = '';
        this.hasAnswered = false;
        this.points = 0;
        this.isOpen = true;
      }
    },
    sendAnswer() {
      var formData = new FormData();
      formData.append('participantId', this.participantId);
      formData.append('answerText', this.answer);
      this.$http
        .post(
          `/api/quiz/${this.quizId}/${this.question.number}/answer`,
          formData,
        )
        .then((res) => {
          this.quiz = res.data;
          this.hasAnswered = true;
        });
    },
    onScoreChanged(data) {
      if (
        data.quizId == this.quizId &&
        data.participant.id == this.participantId
      ) {
        this.points = this.points + data.score;
      }
    },
    onQuestionClosed(data) {
      if (this.quizId == data.quizId) {
        this.isOpen = false;
      }
    },
    onQuestionReopened(data) {
      if (this.quizId == data.quizId) {
        this.isOpen = true;
      }
    },
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
}
@media screen and (max-width: 600px) {
  h3 {
    font-size: 1.2em;
  }
}
</style>
