<template>
  <div>
    
  </div>
</template>
 
<script>
 
export default {
  components: {
  }
}
</script>
 
<style>
.question-previews .list-group-item{
  cursor: pointer;
}
</style>