<template>
  <div id="app">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark shadow">
      <a class="navbar-brand" href="/">Quizmaster</a>
 
      <div class="collapse navbar-collapse" id="main-navbar">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="/host">Host new quiz<span class="sr-only">(current)</span></a>
          </li>
        </ul>
      </div>
    </nav>
 
    <main role="main" class="container mt-4">
      <router-view/>
    </main>
  </div>
</template>
 
<script>
export default {
  name: 'App'
}
</script>