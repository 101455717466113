<template>
    <div>
        <input v-model="title" placeholder="Quiz title"/> <button v-bind:disabled="!hasTypedSomething" v-on:click="createQuiz">Start hosting quiz</button>
    </div>
</template>

<script>
export default {
    data(){
        return {
            title: ""
        }
    },
    computed: {
        hasTypedSomething() {
            return this.title != "";
        }
    },
    methods: {
        createQuiz() {
            var formData = new FormData();
            formData.append("title", this.title);
            this.$http.post(`/api/quiz`, formData).then(res => {
                this.$router.push({name: 'host', params: { quizId: res.data.id }});
            });
        }
    }
}
</script>