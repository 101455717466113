<template>
  <div class="container">
    <div v-if="!hasId()">
      <input v-model="name" placeholder="Nickname" />
      <button v-if="name != ''" v-on:click="saveName">Join!</button>
    </div>
    <div v-else class="row">
      <div class="mainbar">
        <h1>Hi {{ name }}!</h1>
        <br />
        <Question :quizId="quizId" :participantId="id"></Question>
      </div>
      <div class="sidebar">
        <ParticipantList :quizId="quizId"></ParticipantList>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantList from '@/components/ParticipantList.vue';
import Question from '@/components/Question.vue';

export default {
  components: {
    ParticipantList,
    Question,
  },
  props: {
    quizId: String,
  },
  data() {
    return {
      name: '',
      id: '',
    };
  },
  methods: {
    hasName() {
      return this.name !== '';
    },
    hasId() {
      return this.id !== '';
    },
    saveName() {
      var formData = new FormData();
      formData.append('name', this.name);
      this.$http.post(`/api/quiz/${this.quizId}/join`, formData).then((res) => {
        this.id = res.data;
      });
    },
  },
};
</script>

<style scoped>
.mainbar {
  flex-grow: 3;
}

.container {
  /* max-width: 1600px!important; */
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 2em;
  }

  .sidebar {
    display: none;
  }
}
</style>
